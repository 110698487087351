'use strict'

###*
 # @ngdoc service
 # @name mundoData.factory:AssetData

 # @description

###
angular
  .module 'mundoData'
  .factory 'AssetData', [
    'Restangular'
    (Restangular) ->
      AssetDataBase = Restangular
        .service('assets')

      AssetDataBase
  ]
